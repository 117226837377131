import React from "react";
import { Avatar, chakra, Flex, useMediaQuery } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";

const testimonials = [
  {
    name: "Miraj Alam Official",
    content:
      "This application is really conducive in my study, as I am a visually challenged, i do need recorded sessions. Its content and the way of teaching are appriciatable. However, you have to continue working in improving and increasing your content. I would like to add something which should be recorded such as Indian constitution, static general knowledge, computer and psychology. Also facing problem while playing . Doesn't speak buttons. You specify the labels for screenreader.",
  },
  {
    name: "Raji R.",
    content:
      "Sunokitaab is a must app for every child. It helps you in improving your Hindi while also learning your chapters. It has helped me by turning my Hindi a 100% perfect!!! 😍 I recommend everyone to check it out. Thank you Mam for changing my Hindi!!!!! 🙏🙏💐💐👍👍",
  },
  {
    name: "Akshta Pokhalekar",
    content:
      "I love this app. you're doing great job . This is very help full app for students. Thank you so much. I think that if add books then it provide Very gret service . It will help students who can't buy books. In this app thir is no adds so it save time or don't distract .evry thigns are very good about this aap . I will refere this app to my friends also. One's again thanks 😊😊😊😊 It is also useful for blind students or the one who have no time for reed books",
  },
  {
    name: "Surya Raj",
    content:
      "Sunokitaab helps students to read and understand their books more easily. This app provides audio-based teaching materials for the school syllabus. We provide audio-based educational podcast stories and for school students and learners of all ages, Students from CBSE and other state boards can find their syllabus in audio format and learn more easily. W have CBSE, NCERT content for all the classes. Just open your textbooks and start learning from our textbooks.",
  },
  {
    name: "Sumit Sarkar",
    content:
      "I Found this app very useful, and Help me a lot of containing Various topics from various sources. It having no extra cost. It protect from direct eye contact to harmful Mobile radiations",
  },
  {
    name: "Devendra Raj",
    content:
      "The app is amazing for studying , I will say every student should try this once . There are few other things also given in the form of audio you can even enjoy and have some knowledgeble time ( I have one advice that you can have option for asking doubt so the students don't have to search on other applications or any search engine and they are not distracted ) in total I loved your app",
  },
];
function TestmonialCard(props) {
  const { name, content } = props;
  return (
    <Flex
      maxW={{ md: "640px", lg: "640px", sm: "400px", base: "300px" }}
      mx={2}
      direction={{ base: "column-reverse", md: "row" }}
      width={"full"}
      rounded={"xl"}
      p={10}
      justifyContent={"space-between"}
      position={"relative"}
      bg={"white"}
    >
      <Flex
        direction={"column"}
        textAlign={"left"}
        justifyContent={"space-between"}
      >
        <chakra.p fontWeight={"medium"} fontSize={"15px"} pb={4}>
          {content}
        </chakra.p>
        <chakra.p fontWeight={"bold"} fontSize={14}>
          {name}
        </chakra.p>
      </Flex>
      <Avatar
        name={name}
        height={{ lg: "80px", md: "80px", sm: "50px", base: "50px" }}
        width={{ lg: "80px", md: "80px", sm: "50px", base: "50px" }}
        alignSelf={"center"}
        m={{ base: "0 0 15px 0", md: "0 0 0 50px" }}
      />
    </Flex>
  );
}

export default function Testimonials() {
  const [isMinWidthMedium] = useMediaQuery("(min-width: 687px)");

  return (
    <Flex
      textAlign={"center"}
      justifyContent={"center"}
      direction={"column"}
      width={"full"}
    >
      <Marquee gradientColor={isMinWidthMedium ? [123, 203, 140] : [] } speed={isMinWidthMedium ? 50 : 20}>
        {testimonials.map((cardInfo, index) => (
          <TestmonialCard {...cardInfo} index={index} />
        ))}
      </Marquee>
    </Flex>
  );
}

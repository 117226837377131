import * as React from "react";
import { FcAssistant, FcRefresh, FcMoneyTransfer } from "react-icons/fc";
import {
  Text,
  Stack,
  Flex,
  Icon,
  SimpleGrid,
  Container,
} from "@chakra-ui/react";

const Feature = ({ title, text, icon }) => {
  return (
    <Stack alignItems={{ lg: "start", md: "start", sm: "center", base: "center" }} textAlign={{ lg: "start", md: "start", sm: "center", base: "center" }}>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      <Text color={"gray.600"}>{text}</Text>
    </Stack>
  );
};

const VidyaBoxFeatures = (props) => {
  return (
    <Container maxW={"6xl"} pb={24} py={{ sm: 12, base: 12 }}>
      <SimpleGrid columns={{ base: 1, md: 3, sm: 1, base: 1 }} mx={4} spacing={20}>
        <Feature
          icon={<Icon as={FcAssistant} w={10} h={10} />}
          title={"Lifetime Support"}
          text={
            "We're always available to help you with vidya box once you buy it. you can contact us regarding any sort of issues including damages, returns and content update etc"
          }
        />
        <Feature
          icon={<Icon as={FcRefresh} w={10} h={10} />}
          title={"Academic Updates"}
          text={
            "Get updated content on every class you pass on to upto 4 academic years"
          }
        />
        <Feature
          icon={<Icon as={FcMoneyTransfer} w={10} h={10} />}
          title={"15 Days of Money Back Guarantee"}
          text={
            "In case you dont' feel vidya box isn't for you, or you face any issues you can return it back withing 15 days and get total refund"
          }
        />
      </SimpleGrid>
    </Container>
  );
};

export default VidyaBoxFeatures;

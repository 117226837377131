import * as React from "react";
import {
  Container,
  Flex,
  Text,
  Stack,
  StackDivider,
  Icon,
  Box,
  HStack,
  chakra,
  SimpleGrid,
} from "@chakra-ui/react";
import { MdSubscriptions, MdOutlineUpdate } from "react-icons/md";
import { BiDownload } from "react-icons/bi";
import { FaHeart } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";
import Testimonials from "../index/testimonials";
import PlayStoreBadge from "../icons/playstore_badge";
import AppStoreBadge from "../icons/appstore_badge";
import BasicStatistics from "../../components/index/stats";

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text color={"blackAlpha.700"} fontWeight={600}>
        {text}
      </Text>
    </Stack>
  );
};

export default function AboutApp() {
  return (
    <Stack
      alignItems={"center"}
    >
      <SimpleGrid justifyItems={"center"} p={6} mt={"24px"} maxW={"6xl"} columns={{ md: 1, lg: 2 }}>
        <Stack spacing={6}>
          <Text
            fontSize={{ lg: "3rem", md: "3rem", sm: "3rem", base: "4xl" }}
            fontWeight="bold"
            lineHeight="none"
            letterSpacing={{ base: "normal", md: "tight" }}
            color={"gray.900"}
          >
            Listen, Learn & Educate Bharat with{" "}
            <Text
              display={{ base: "block", lg: "inline" }}
              w="full"
              bgClip="text"
              bgGradient="linear(to-r, green.400,purple.500)"
              fontWeight="extrabold"
            >
              SunoKitaab
            </Text>
          </Text>
          <chakra.h1 color={"gray.500"}>
            CBSE, RBSE, WBBSE, NCERT Audio books mapped just according to your
            textbook and up to date. Download lecture to listen later offline, without internet or wifi.
          </chakra.h1>
          <Stack
            pt={10}
            spacing={4}
            maxW={{ md: 'md' }}
            divider={<StackDivider borderColor={"blackAlpha.300"} />}
          >
            <Feature
              icon={
                <Icon as={MdOutlineUpdate} color={"yellow.500"} w={5} h={5} />
              }
              iconBg={"yellow.100"}
              text={"Up to Date Content"}
            />
            <Feature
              icon={
                <Icon as={MdSubscriptions} color={"green.500"} w={5} h={5} />
              }
              iconBg={"green.100"}
              text={"Affordable Subscription with Free Trial"}
            />
            <Feature
              icon={<Icon as={BiDownload} color={"purple.500"} w={5} h={5} />}
              iconBg={"purple.100"}
              text={"Download Lectures for Offline Listening"}
            />
          </Stack>
          <Stack
            style={{
              marginTop: "30px",
            }}
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: "column", sm: "row" }}
          >
            <a href="https://sunokitaab.com/app">
              <PlayStoreBadge />
            </a>
            {/* <a href="https://apps.apple.com/us/app/sunokitaab-ncert-audiobooks/id6444225774">
              <AppStoreBadge />
            </a> */}
          </Stack>
        </Stack>
        <Box maxW={{ md: "2xl" }}>
          <StaticImage
            alt={"SunoKitaab App"}
            src={"../../images/mobiles.png"}
            objectFit={"cover"}
          />
        </Box>
      </SimpleGrid>
      <BasicStatistics />
      <Container px={0} mt={20} py={24} bgColor={"#7bcb8c"} maxW={"full"}>
        <Container pb={12} maxW={"6xl"}>
          <Text
            fontSize={{ base: "1.3rem", sm: "1.3rem", md: "2rem", lg: "2rem" }}
          >
            <HStack>
              <Text color={"white"} fontWeight={"bold"}>
                STUDENTS
              </Text>
              <FaHeart color="white" />
              <Text color={"white"} fontWeight={"bold"}>
                WHAT WE'RE DOING!
              </Text>
            </HStack>
          </Text>
          <Text color={"white"}>
            Download our app now to listen to best lectures from all over india,
            We're waiting for your Feedback
          </Text>
        </Container>
        <Testimonials />
      </Container>
    </Stack>
  );
}

import * as React from "react";
import MasterLayout from "../layouts/master";
import VidyaBox from "../components/index/vidyabox";
import VidyaBoxFeatures from "../components/index/vidyabox_features";
import AboutApp from "../components/index/app";
import Faq from "../components/index/faq";
import SEO from "../components/seo/seo";
import { Box, SimpleGrid, chakra, VStack, Container } from "@chakra-ui/react";
import { graphql } from "gatsby";

const IndexPage = (props) => {
  const { data } = props

  return (
    <>
      <SEO
        title="SunoKitaab NCERT Audiobook For K3, K10, K12, NEET, JEE, UPSC, Police Exams and Banks Exams"
      />
      <MasterLayout>
        <AboutApp />
        <VidyaBox />
        <VidyaBoxFeatures />
        <Container maxW={'6xl'}>
          <chakra.h2
            fontSize={'4xl'}
            fontWeight={"bold"}
            py={0}
            mt={24}
          >Listen to Audio Books from Class 1 to 12</chakra.h2>
          <chakra.h4>
            We've uploaded audio books/lectures for you try so that you won't need to download the app just to know how we deliver content. To listen to entire lecture or to get a proper look and feel please download our app from <a style={{ color: "green" }} href="https://sunokitaab.com/app">PlayStore</a>
          </chakra.h4>
        <SimpleGrid py={12} columns={{ lg: 3, md: 3, sm: 2, base: 1 }} spacing={4}>
          {data.serverGql.getAllClassesAndMeta.classes.filter((c) => c.name != "Kids Section" && c.name != "Grammar").map((cl) => {
            return (
              <a href={`/${cl.name.toLowerCase().split(" ").join("-")}-cbse`}>
                <Box
                  maxW="container.sm"
                  borderWidth="1px"
                  borderRadius="lg"
                  overflow="hidden"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  p={6}
                >
                  <VStack alignItems={"start"}>
                    <chakra.h2 fontWeight={"bold"} fontSize={"4xl"}>
                      {cl.name}
                    </chakra.h2>
                    <chakra.h3 fontSize={"md"}>
                      Chapters{" "}
                      <b>{` ${data.serverGql.getAllClassesAndMeta.meta.find(
                        (m) => m.classId == cl.id
                      )?.chapters
                        }`}</b>
                    </chakra.h3>
                    <chakra.h3 fontSize={"md"}>
                      Lectures{" "}
                      <b>{` ${data.serverGql.getAllClassesAndMeta.meta.find(
                        (m) => m.classId == cl.id
                      )?.lectures
                        }`}</b>
                    </chakra.h3>
                  </VStack>
                </Box>
              </a>
            );
          })}
        </SimpleGrid>
        </Container>
        <Faq />
      </MasterLayout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query classesQuery {
    serverGql {
      getAllClassesAndMeta {
        classes {
          id
          name
        }
        meta {
          chapters
          classId
          lectures
        }
      }
    }
  }
`;
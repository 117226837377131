import * as React from "react";
import {
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Container,
  Text
} from "@chakra-ui/react";

function StatsCard(props) {
  const { title, stat } = props;
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={"5"}
      border={"1px solid"}
      borderColor={"green.400"}
      rounded={"lg"}
    >
      <StatLabel fontWeight={"medium"} isTruncated>
        {title}
      </StatLabel>
      <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
        {stat}
      </StatNumber>
    </Stat>
  );
}

export default function BasicStatistics() {
  return (
    <Container maxW="6xl" mx={"auto"} pt={28} pb={12} px={{ base: 2, sm: 12, md: 17 }}>
      <Text
        textAlign={"center"}
        fontSize={{ lg: "3xl", md: "2xl", sm: "2xl", base: "2xl" }}
        py={4}
      >
        We're unstoppable in the terms of <b>"Content Production"</b> 🚀
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard title={"Lectures Recorded"} stat={"10,000+"} />
        <StatsCard title={"Podcasts"} stat={"150+ Podcasts"} />
        <StatsCard title={"Langauges"} stat={"3+ Languages Pan India"} />
      </SimpleGrid>
    </Container>
  );
}

import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Text,
} from "@chakra-ui/react";

const Faq = () => {
  return (
    <Container py={90} maxW={"6xl"}>
      <Text
        fontWeight={"bold"}
        fontSize={'1.2rem'}
        py={8}
      >
        Frequently Asked Questions
      </Text>
      <Accordion allowToggle>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How will Vidya Box work?
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            You will get an printed index on which all the lectures will be listed associated with a number, you will enter that associated number on Vidya Box's keypad to play the desired lecture
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                How will i get my lectures updated in my Vidya Box
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            We will manually update content on your device as you progress onto your next academic session.
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  );
};

export default Faq;
